<template>
  <div class="filter-pan">

    <div class="mb-2">
      <span class="label">
        项目分类
      </span>
    <btn-1 class="btn-btn" v-for="i in 20" :key="i">方案及设计</btn-1>

    </div>

  <div  class="mb-2">
      <span class="label">
        项目预算
      </span>
    <btn-1 class="btn-btn" >1千以下</btn-1>
    <btn-1 class="btn-btn" >1千~5千</btn-1>
    <btn-1 class="btn-btn" >5千~1万</btn-1>
    <btn-1 class="btn-btn" >1万~5万</btn-1>
    <btn-1 class="btn-btn" >5万~10万</btn-1>
    <btn-1 class="btn-btn" >10万以上</btn-1>
    <btn-1 class="btn-btn" >可议价</btn-1>
    </div>

    <div  class="mb-2">
      <span class="label">
        项目状态
      </span>
      <btn-2 class="btn-btn">竞标中</btn-2>
      <btn-2 class="btn-btn">开发中</btn-2>
      <btn-2 class="btn-btn">已完成</btn-2>
    </div>

    <div  class="mb-2">
      <span class="label">
        项目排序
      </span>
      <btn-2 class="btn-btn">最新发布</btn-2>
      <btn-2 class="btn-btn">项目预算</btn-2>
      <btn-2 class="btn-btn">项目周期</btn-2>
    </div>

  </div>
</template>

<script>
import Btn1 from './Btn1.vue'
import Btn2 from './Btn2.vue'
export default {
  components: { Btn1, Btn2 },
  name: 'FilterPan'
}
</script>

<style lang="scss" scoped>
  .filter-pan {
    padding: 25px;
    margin-bottom: 15px;
    background-color: #fff;
    .label {
      display: inline-block;
    padding-right: 15px;
    color: #999;
    font-size: 14px;
    }

    .btn-btn {
      margin-right: 10px;
      margin-bottom: 8px;
    }
  }
</style>