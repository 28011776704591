<template>
  <!-- <span class="btn1"> -->
  <button class="btn">
    <slot />
  </button>
  <!-- </span> -->
</template>

<script>
export default {
  name: "Btn2",
  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
// .btn1 {
// display: inline-block;

button.btn {
  cursor: pointer;
  padding: 8px;
  color: #666;
  background: #fff;
  // border-color: #b9dfe7;
  border-radius: 4px;
  user-select: none;
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  text-align: center;
  box-sizing: border-box;
  outline: none;
  margin: 0;
  transition: 0.5s;
  font-weight: 500;
  font-size: 14px;
  border: none;

  &:hover,
  &:active,
  &.active {
    background: #4fafc2;
    color: #fff;
  }
}
// }
</style>