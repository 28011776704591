<template>
  <div class="project-item" :class="{ active: active }" @click="active = true">
    <div class="content">
      <div
        class="head d-flex justify-content-between align-items-center border-bottom pb-2 mb-3"
      >
        <span class="title fs-4">PC端后台开发</span>
        <div>
          <span class="num">
            <i class="iconfont icon-chakan"></i>
            浏览量 236</span
          >
          <span class="num">
            <i class="iconfont icon-kaoshijihua"></i>
            竞标数 16</span
          >
        </div>
      </div>

      <div class="bottom d-flex align-items-start">
        <div class="left">
          <img src="../../assets/images/project-item.png" alt="" srcset="" />
        </div>
        <div class="right">
          <div class="mb-2">
            <i class="iconfont icon-tubiaozhizuomoban"></i>
            <span>网站开发</span>
            <span class="pl-3 text-black-50">团队开发</span>
          </div>
          <div class="mb-2">
            <span class="text-black-50">金额：</span>
            <span class="text-red">50000</span>
            <span class="px-3 text-black-50">|</span>
            <span class="text-black-50">周期：</span>
            <span>60天</span>
            <span class="px-3 text-black-50">|</span>
            <span class="text-black-50">项目状态：</span>
            <span>竞标中</span>
          </div>
          <div class="mb-2">
            <span class="text-black-50">发布时间：</span>
            <span >2020-01-05</span>
            <span class="px-2 text-black-50">|</span>
            <span class="text-black-50">截至：</span>
            <span class="pl-1">2020-02-05</span>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProjectItem',
  data () {
    return {
      active: false
    }
  }
};
</script>

<style lang="scss" scoped>
.project-item {
  cursor: pointer;
  background-color: #fff;
  // border-bottom: 1px solid #dedede;
  margin-bottom: 15px;
  border-left: 1px solid transparent;
  &:hover,
  &:active, &.active {
    border-left-color: #4fafc2;
    box-shadow: 0 0 2px 0 rgb(0 0 0 / 8%), 0 0 4px 0 rgb(0 0 0 / 12%);
  }
  .content {
    padding: 20px 30px;
    border-left: 4px solid transparent;
    transition: 0.3s;
    &:hover {
      border-left-color: #4fafc2;
      .title {
        color: #4fafc2;
        transition: 0.3s;
      }
    }

    .num {
      display: inline-block;
      font-size: 14px;
    }
    .num + .num {
      margin-left: 15px;
    }

    i {
      vertical-align: middle;
      color: #999;
    }

    .bottom {
      .left {
        padding-right: 15px;
              img {
        width: 180px;
        border-radius: 4px;
      }
      }
      .right {
        font-size: 14px;
        .text-red {
          color: red;
        }
      }
    }
  }
}
</style>