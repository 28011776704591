<template>
  <el-row :gutter="20">
    <el-col :xs="24" :sm="24" :md="18" :lg="18" :xl="18" class="mb-4">
      <el-input placeholder="搜索自由职业者" />
    </el-col>
    <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6" class="mb-4">
      <el-button type="primary" style="width: 100%"
        >立即搜索</el-button
      >
    </el-col>
  </el-row>
</template>

<script>
export default {
  name: 'Search'
}
</script>

<style>

</style>