<template>
  <div class="hall-home">
    <r-row>
      <el-row :gutter="20">
        <el-col :xs="24" :sm="24" :md="18" :lg="18" :xl="18">
          <div class="left">
            <search />
            <filter-pan />

            <!-- 项目列表 -->
            <project-item />
            <project-item />
            <project-item />
            <project-item />
            <project-item />
          </div>
        </el-col>

        <!-- 侧边 -->
        <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6">
          <sider />
        </el-col>
      </el-row>
    </r-row>
  </div>
</template>

<script>
import RRow from "../../components/RRow.vue";
import Sider from "../../components/Sider.vue";
import Search from "./Search.vue";
import FilterPan from "./FilterPan";
import ProjectItem from "./ProjectItem";
export default {
  components: { RRow, Sider, Search, FilterPan, ProjectItem },
  name: "Hall",
};
</script>

<style lang="scss">
.hall-home {
  padding-top: 30px;
  padding-bottom: 20px;
  background: #f0f0f0;
}
</style>